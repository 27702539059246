@font-face {
  font-family: Poppins;
  src: url(./fonts/poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: Roboto-Serif;
  src: url(./fonts/robotoSerif/RobotoSerif-Medium.ttf);
}
@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto-Mono;
  src: url(./fonts/Roboto-Mono/RobotoMono-VariableFont_wght.ttf);
}

html,
/* body {
  width: 100%;
  min-width: 200px;
  height: 100%;
  overflow-x: hidden;
  
} */

* {
  box-sizing: border-box;
  font-family: Roboto;
  /* -ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none;  Firefox */
  /* outline: 1px solid #f00 !important;
  opacity: 1 !important;
  visibility: visible !important; */
  margin: 0;
  padding: 0;
}
/* * ::-webkit-scrollbar{
  display: none;
} */

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto-Serif','Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
 
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  
  
}